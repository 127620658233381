import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { getWidgetSettings } from '@wix/ambassador-innovation-widget-v1-widget-settings/http';
import { settingsSlice } from './settingsSlice';
import { formatSettingsResonse } from './utils';

export const getSettings = createAppAsyncThunk(
  'settings/get',
  async (_, { dispatch, extra: { httpClient } }) => {
    const resp = await httpClient.request(getWidgetSettings({}));
    if (resp.status === 200) {
      dispatch(settingsSlice.actions.set(formatSettingsResonse(resp.data)));
    }
  },
);
