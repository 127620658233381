import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { type AppDispatch, getStore, type RootState } from './store';
import { useMemo } from 'react';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { useHttpClient } from '../hooks/useHttpClient';
import { useDuplexer } from '../hooks/useDuplexer';
import { useMockClient } from '../__tests__/HttpClientContext';
import { usePreloadedState } from '../__tests__/PreloadedStateContext';
import { usePresenceClient } from '../hooks';

export type AppParams = {
  appDefId: string;
  instanceId: string;
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const isDev = process.env.NODE_ENV !== 'production';

export const useGetStore = (instanceToken: string) => {
  const httpClient = useHttpClient(instanceToken);
  const mockClient = useMockClient();
  const socket = useDuplexer(instanceToken);
  const { t } = useTranslation();
  const bi = useBi();
  const preloadedState = usePreloadedState();

  return useMemo(
    () =>
      getStore({
        httpClient: mockClient ?? httpClient,
        t,
        bi,
        socket,
        preloadedState,
        needDevTools: isDev,
        needLogger: isDev && !mockClient, // no need in test logs
      }),
    // We never recreate the storage unless it's a new page load
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
};
