import React, { useContext } from 'react';
import { ControllerProps } from '../components/ControllerValidation';
import { WidgetProps } from '@wix/yoshi-flow-editor';

export type RootProps = Partial<WidgetProps<ControllerProps>>;

export const RootPropsContext = React.createContext<RootProps>({});

export const RootPropsProvider: React.FC<{
  p: RootProps;
}> = ({ p, children }) => {
  return (
    <RootPropsContext.Provider value={p}>{children}</RootPropsContext.Provider>
  );
};

export function useRootProps() {
  return useContext(RootPropsContext);
}
