import { PRESENCE_METADATA_URL } from '../../constants';
import { createAppAsyncThunk } from '../createAppAsyncThunk';
import { geoLocationSlice } from './geoLocationSlice';

export const getGeoLocation = createAppAsyncThunk(
  'getGeoLocation/get',
  async (_, { dispatch, extra: { httpClient } }) => {
    const resp = await httpClient.get<{ metadata: { location: string } }>(
      PRESENCE_METADATA_URL,
    );
    if (resp.status === 200) {
      dispatch(geoLocationSlice.actions.set(resp.data.metadata.location));
    }
  },
);
