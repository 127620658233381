import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
  Middleware,
} from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import {
  messagesReducer,
  layoutReducer,
  settingsReducer,
  flagsReducer,
  geoLocationReducer,
} from '../features';
import { IHttpClient, TFunction } from '@wix/yoshi-flow-editor';
import { VisitorLogger } from '@wix/yoshi-flow-editor/external-types/bi';
import { SocketAPI } from '../hooks/useDuplexer';

export const logger = createLogger({
  predicate: (_, action) => true,
}) as Middleware;

export const reducer = combineReducers({
  messages: messagesReducer,
  layout: layoutReducer,
  settings: settingsReducer,
  flags: flagsReducer,
  geoLocation: geoLocationReducer,
});

export type GetStoreParams = {
  httpClient: IHttpClient;
  t: TFunction;
  bi: VisitorLogger;
  socket: SocketAPI | null;
  preloadedState?: Partial<RootState>;
  needLogger: boolean;
  needDevTools: boolean;
};

export const getStore = ({
  httpClient,
  t,
  bi,
  socket,
  preloadedState,
  needLogger,
  needDevTools,
}: GetStoreParams) =>
  configureStore({
    preloadedState,
    reducer,
    middleware: (getDefaultMiddleware) => {
      const middlewares = getDefaultMiddleware({
        thunk: {
          extraArgument: {
            httpClient,
            t,
            bi,
            socket,
          },
        },
        serializableCheck: false,
      });
      return needLogger ? middlewares.concat(logger) : middlewares;
    },
    devTools: needDevTools,
  });

export type AppDispatch = ReturnType<typeof getStore>['dispatch'];
export type RootState = ReturnType<typeof reducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
