import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getGeoLocation } from './geoLocationThunks';

type GeoLocation = {
  location: string;
};

export type GeoLocationState = {
  value: GeoLocation | null;
  request: 'idle' | 'loading' | 'error';
  currentRequestId?: string;
};

const initialState: GeoLocationState = {
  value: null,
  request: 'idle',
  currentRequestId: undefined,
};

export const geoLocationSlice = createSlice({
  name: 'geoLocation',
  initialState,
  reducers: {
    set: (state, { payload }: PayloadAction<string>) => {
      state.value = {
        location: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGeoLocation.pending, (state, action) => {
      state.currentRequestId = action.meta.requestId;
      state.request = 'loading';
    });
    builder.addCase(getGeoLocation.fulfilled, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.request = 'idle';
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(getGeoLocation.rejected, (state, action) => {
      if (state.currentRequestId === action.meta.requestId) {
        state.request = 'error';
        state.currentRequestId = undefined;
      }
    });
  },
});

export const { reducer: geoLocationReducer } = geoLocationSlice;
