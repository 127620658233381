import { RootState } from '../../store';
import { createSelector } from '@reduxjs/toolkit';

export const value = (state: RootState) => state.geoLocation.value;
export const request = (state: RootState) => state.geoLocation.request;

export const isLoaded = createSelector(value, (v) => Boolean(v));
export const isLoading = createSelector(request, (r) => r === 'loading');
export const isError = createSelector(request, (r) => r === 'error');

export const location = createSelector(value, (v) => v?.location);
export const shouldLoad = createSelector(
  value,
  request,
  (v, r) => v === null && r === 'idle',
);
