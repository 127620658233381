import { useAppDispatch, useAppSelector } from '../../../../../store';
import {
  addMessage,
  AddMessagePayload,
  selectFlags,
  selectMessages,
  selectSettings,
} from '../../../../../features';
import { useEffect, useMemo, useState } from 'react';

import {
  Sender,
  MessageType,
} from '@wix/ambassador-innovation-widget-v1-message/types';
import { useTranslation } from '@wix/yoshi-flow-editor';

export const useIntroSequence = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isSequenceFulfilled, setIsSequenceFulfilled] = useState(true);
  const settingsLoaded = useAppSelector(selectSettings.isLoaded);
  const messagesLoaded = useAppSelector(selectMessages.isLoaded);

  const introMessage = useAppSelector(selectSettings.introMessage);
  const legalMessage = useAppSelector(selectSettings.legalMessage);
  const offlineShouldShowContactForm = useAppSelector(
    selectSettings.offlineShouldShowContactForm,
  );
  const maybeSendIntro = useAppSelector(selectMessages.shouldSendIntro);
  const maybeSendLegal = useAppSelector(selectMessages.shouldSendLegal);
  const isContact = useAppSelector(selectFlags.isContact);
  const noContactFormOnLastPage = useAppSelector(
    selectMessages.noContactFormOnLastPage,
  );

  const shouldFullfillSequence = useMemo(
    () => settingsLoaded && messagesLoaded,
    [settingsLoaded, messagesLoaded],
  );

  const shouldSendIntro = useMemo(
    () => Boolean(maybeSendIntro && introMessage),
    [maybeSendIntro, introMessage],
  );

  const shouldSendLegal = useMemo(
    () => Boolean(maybeSendLegal && legalMessage),
    [maybeSendLegal, legalMessage],
  );

  const shouldSendContact = useMemo(
    () => !isContact && noContactFormOnLastPage && offlineShouldShowContactForm,
    [isContact, noContactFormOnLastPage, offlineShouldShowContactForm],
  );

  const payload = useMemo(() => {
    const result: AddMessagePayload[] = [];
    if (shouldSendIntro) {
      result.push({
        text: introMessage as string,
        sender: Sender.ASSISTANT,
        messageType: MessageType.INTRO,
      });
    }
    if (shouldSendLegal) {
      result.push({
        text: legalMessage as string,
        sender: Sender.ASSISTANT,
        messageType: MessageType.LEGAL,
      });
    }
    if (shouldSendContact) {
      result.push({
        text: t('app.widget.form.offlineBeforeText'),
        sender: Sender.ASSISTANT,
        messageType: MessageType.CONTACT_FORM,
      });
    }
    return result;
  }, [
    shouldSendIntro,
    shouldSendLegal,
    shouldSendContact,
    introMessage,
    legalMessage,
    t,
  ]);

  useEffect(() => {
    if (shouldFullfillSequence && payload.length) {
      setIsSequenceFulfilled(false);
      dispatch(addMessage(payload)).finally(() => {
        setIsSequenceFulfilled(true);
      });
    }
  }, [shouldFullfillSequence, payload, dispatch]);

  return {
    isSequenceFulfilled,
  };
};
