import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DeviceType, Group } from '@wix/presence-client';
import { Duplexer } from '@wix/duplexer-js';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useRootProps } from '../components/AiAssistantWidget/Widget/contexts';
import { useAppDispatch, useAppSelector } from '../store';
import { getGeoLocation, selectGeoLocation, selectMessages } from '../features';

const KEEP_ALIVE_INTERVAL = 90 * 1000; // 1.5 minutes
const IDLE_TIMEOUT = 3 * 60 * 60 * 1000; // 3 hours

export interface Presence {
  deviceType: DeviceType;
  location: string;
  timestamp: string | number;
  pageName: string;
}

export const usePresenceClient = () => {
  const dispatch = useAppDispatch();

  const { isEditor, isMobile } = useEnvironment();
  const [presence, setPresence] = useState<Presence>({} as Presence);
  const conversationId = useAppSelector(selectMessages.conversationId);
  const shouldLoadLocation = useAppSelector(selectGeoLocation.shouldLoad);
  const geo = useAppSelector(selectGeoLocation.value);

  const { instanceToken, page } = useRootProps();

  const deviceType = useMemo(
    () => (isMobile ? DeviceType.MOBILE : DeviceType.DESKTOP),
    [isMobile],
  );

  const presenceGroup = useRef<Group<Presence>>();
  const getSignedInstance = useMemo(
    () => () => instanceToken!,
    [instanceToken],
  );

  const connectPresence = useCallback(
    () =>
      presenceGroup.current?.track(() => {}, {
        watchGroup: false,
      }),
    [],
  );

  const updatePresence = (newPresence: Partial<Presence>) => {
    const updatedPresence = {
      ...presence,
      ...newPresence,
    };
    setPresence(updatedPresence);
    if (updatedPresence.pageName && presenceGroup.current) {
      void presenceGroup.current.setState(updatedPresence);
    }
  };

  useEffect(() => {
    void (async () => {
      if (isEditor) {
        return;
      }

      const presenceClient = await import('@wix/presence-client');
      const { default: PresenceClient, Scope } = presenceClient;

      const presenceInstance = new PresenceClient({
        duplexer: new Duplexer('duplexer.wix.com', {
          instanceUpdater: {
            getInstance: getSignedInstance,
          },
        }),
        getSignedInstance,
      });

      const initialState = {
        deviceType,
        location: geo?.location ?? '',
        timestamp: Date.now(),
        pageName: page?.name ?? '',
      };
      setPresence(initialState);

      presenceGroup.current = presenceInstance.group(Scope.VIEWER, {
        initialState,
        keepAliveInterval: KEEP_ALIVE_INTERVAL,
        onIdle: () => {
          presenceGroup.current?.disconnect();
        },
        onActive: connectPresence,
        isIdleMs: IDLE_TIMEOUT,
      });
      connectPresence();
    })();
  }, []); // Run once

  useEffect(() => {
    if (shouldLoadLocation) {
      dispatch(getGeoLocation());
    }
  }, [shouldLoadLocation, dispatch]);

  useEffect(() => {
    updatePresence({
      pageName: page?.name ?? '',
    });
  }, [page]);

  useEffect(() => {
    updatePresence({
      location: geo?.location ?? '',
    });
  }, [geo]);

  useEffect(() => {
    updatePresence({});
  }, [conversationId]); // update presence on conversationId change
};
